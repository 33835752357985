import React from 'react';
import API_URL from '../config/contentApi';

function ColumnSection({ data }) {
  if (!data) {
    return <div>Loading column section...</div>;
  }

  const { Title, Body, Column, ImageSize } = data;

  // Determine max-width based on ImageSize
  const imageMaxWidth = ImageSize === 'Small' ? 100 : 260;

  // Compute the class for the .columns div
  const columnsClass =
    Column && Column.length === 2
      ? 'columns two-columns'
      : Column.length === 1
      ? 'columns one-column'
      : 'columns';

  return (
    <div className="column-section">
      {/* Conditionally render the section title if it exists */}
      {Title && <h2 className="column-section-title">{Title}</h2>}

      {/* Conditionally render the Body if it exists */}
      {Body && <p className="column-section-body">{Body}</p>}

      {/* Conditionally render the .columns div only if there are columns */}
      {Column && Column.length > 0 && (
        <div className={columnsClass}>
          {Column.map((col) => {
            // Determine the appropriate CSS class based on column content
            const columnClass = col.Icon && !col.Title && !col.Description
              ? 'icon-only'
              : col.Description
              ? 'with-description'
              : 'no-description';

            return (
              <div key={col.id} className={`column ${columnClass}`}>
                {/* Render the icon if it exists */}
                {col.Icon && col.Icon.url && (
                  <div className="column-icon-container">
                    <img
                      src={`${API_URL}${col.Icon.url}`}
                      alt={col.Icon.alternativeText || col.Title || 'Icon'}
                      className="column-icon"
                      style={{ maxWidth: `${imageMaxWidth}px` }}
                    />
                  </div>
                )}

                {/* Render the title if it exists */}
                {col.Title && (
                  <div className="column-title-container">
                    <h3>{col.Title}</h3>
                  </div>
                )}

                {/* Render the description if it exists */}
                {col.Description && (
                  <div className="column-description-container">
                    <p>{col.Description}</p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default ColumnSection;