import { useEffect } from 'react';

const VigPixel = ({ iParam, sParam }) => {
  useEffect(() => {
    if (!iParam || !sParam) {
      console.warn('VigPixel parameters are missing');
      return;
    }

    // Check if the script is already added
    const existingScript = document.querySelector(`script[src*="${iParam}"]`);
    if (existingScript) {
      console.warn('VigPixel script already exists');
      return;
    }

    // Construct the script code as a string
    const scriptCode = `
        var d = document,
            s = "https://a.smtrk.net/trk?t=s&i=${iParam}&p=t,i,x&s=${sParam}&r=" + encodeURIComponent(d.referrer) + "&u=" + encodeURIComponent(d.URL),
            n = d.createElement("script"),
            e = d.getElementsByTagName("script")[0];
        n.async = true;
        n.src = s;
        e.parentNode.insertBefore(n, e);
    `;

    // Create a script element and set its content
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.text = scriptCode; // Use 'text' to set the script content

    // Append the script to the head or body
    document.head.appendChild(script);

    return () => {
      // Cleanup the script when the component unmounts
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, [iParam, sParam]);

  return null; // This component does not render anything
};

export default VigPixel;