import React from 'react';
import API_URL from '../config/contentApi';

function OverlapBlock({ data }) {
  if (!data) {
    return <div>Loading...</div>;
  }

  const { Title, Description, Image } = data;

  // Determine the best image URL with fallback
  const imageUrl = Image ? `${API_URL}${Image.url}` : null;

  // Fallback to medium or small format if available
  const responsiveImageUrl = 
    (Image?.formats?.large?.url && `${API_URL}${Image.formats.large.url}`) ||
    (Image?.formats?.medium?.url && `${API_URL}${Image.formats.medium.url}`) ||
    (Image?.formats?.small?.url && `${API_URL}${Image.formats.small.url}`) ||
    imageUrl;

  return (
    <div className="overlap-block">
      <div className="overlap-block-content">
        <div className="text-container">
          {Title && <h2 className="overlap-title">{Title}</h2>}
          {Description && <p className="overlap-description">{Description}</p>}
        </div>
        
        {responsiveImageUrl && (
          <div className="image-container">
            <img 
              src={responsiveImageUrl} 
              alt={Title || 'Overlap Block Image'} 
              className="overlap-image"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default OverlapBlock;