import React from 'react';
import API_URL from '../config/contentApi';
import { replacePlaceholders } from '../utils/replacePlaceholders';
import { ReactComponent as AddressIcon } from '../assets/address-icon.svg';

function MapBlock({ data, variables, scrollToForm }) {
  if (!data) {
    return <div>Loading...</div>;
  }

  const { Supertitle, Title, Body, Image } = data;

  // Determine the best image URL with fallback
  const imageUrl = Image ? `${API_URL}${Image.url}` : null;
  const responsiveImageUrl =
    (Image?.formats?.small?.url && `${API_URL}${Image.formats.small.url}`) ||
    (Image?.formats?.thumbnail?.url && `${API_URL}${Image.formats.thumbnail.url}`) ||
    imageUrl;

  // Replace placeholders in text if variables are provided
  const processedTitle = variables ? replacePlaceholders(Title, variables) : Title;
  const processedBody = variables ? replacePlaceholders(Body, variables) : Body;

  // Extract address, city, state, phone, and iframeSrc variables
  const address = variables?.address || 'Address';
  const city = variables?.city || 'City';
  const state = variables?.state || 'State';
  const phone = variables?.phone || 'Phone number';
  const iframeSrc = variables?.mapUrl;

  return (
    <div className="map-block">
      <div className="map-block-content">
        <div className="text-container">
          {Supertitle && <h3 className="map-supertitle">{Supertitle}</h3>}
          {processedTitle && <h2 className="map-title">{processedTitle}</h2>}
          {processedBody && <p className="map-description">{processedBody}</p>}

          {/* Address Section with Buttons */}
          <div className="map-address-container">
            <div className="map-address">
              <AddressIcon className="address-icon" />
              <div className="address-content">
                <p>{address}</p>
                <p>{city}, {state}</p>
              </div>
            </div>

            {/* Buttons Section */}
            <div className="map-buttons">
              <a href={`tel:${phone}`} className="map-button secondary-button">
                {phone}
              </a>
              <button
                className="map-button primary-button"
                onClick={scrollToForm}
              >
                Contact Us
              </button>
            </div>
          </div>
        </div>

        <div className="image-container-wrapper">
          {responsiveImageUrl && (
            <div className="image-container">
              <img
                src={responsiveImageUrl}
                alt={Title || 'Map Block Image'}
                className="map-image"
              />
            </div>
          )}

          {/* Map Embed Section */}
          {iframeSrc && (
            <div className="map-embed-container">
              <iframe
                src={iframeSrc}
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MapBlock;