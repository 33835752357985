export const contentMap = {
'www.budget-blinds.us': {
    fontFamily: "'Avenir Next', sans-serif",
    metaTitle: 'Budget Blinds',
    components: {
      default: {
        default: [
          { type: 'heroBanner', contentType: 'hero-banners', id: 'glakiitzv1gxrtwnvqmwzunw' },
          { type: 'columnSection', contentType: 'column-blocks', id: 'ny6ct80r97iw7i8eavp16zyb' },
          { type: 'columnSection', contentType: 'column-blocks', id: 'k14unhy37i1lnotto2yz3swe' },
          { type: 'carousel', contentType: 'carousels', id: 'lpqjqcl2yk31hfkj2mzw2z8i' },
          { type: 'formBlock', contentType: 'form-blocks', id: 'wcv7rtalcx7kua7uk0jxkd04' },
        ],
        blinds: [
          { type: 'singleContent', contentType: 'single-contents', id: 'mcpistrj49xsysz4j1zzfa9f' },
          { type: 'heroBanner', contentType: 'hero-banners', id: 'hjv19ivxzl0xixp5cdfzalr6' },
          { type: 'formBlock', contentType: 'form-blocks', id: 'wcv7rtalcx7kua7uk0jxkd04' },
          { type: 'rowSection', contentType: 'row-blocks', id: 'gm7kdkws7si63kyn7d9ahdkl' },
          { type: 'columnSection', contentType: 'column-blocks', id: 'x2btmy2o53u69dki6j3x9f8w' },
          { type: 'columnSection', contentType: 'column-blocks', id: 'v0uh95d44rgm0zuyloc0fkfa' },
          { type: 'columnSection', contentType: 'column-blocks', id: 'p38pvytwrkm814txfh19e8y3' },
          { type: 'rowSection', contentType: 'row-blocks', id: 'u1bwbhm970j39onpa93eafyl' },
          { type: 'carousel', contentType: 'carousels', id: 'kj0nrt2tt562x0o5zu7az2we' },
          { type: 'columnSection', contentType: 'column-blocks', id: 'ia7rbn7znlnlsop9zyt02jpo' },
          { type: 'rowSection', contentType: 'row-blocks', id: 'go8lg3v1inwc2q8ea5nyvhxt' },
        ],
        shades: [
          { type: 'singleContent', contentType: 'single-contents', id: 'sxxes3vjnh9q7iob5q3mwsfd' },
          { type: 'heroBanner', contentType: 'hero-banners', id: 'n94twmgz0j2i3960uxkhx1jc' },
          { type: 'formBlock', contentType: 'form-blocks', id: 'wcv7rtalcx7kua7uk0jxkd04' },
          { type: 'rowSection', contentType: 'row-blocks', id: 'u1u8030l0bxqy2aw5uaf07a0' },
          { type: 'columnSection', contentType: 'column-blocks', id: 'uy3u5ls36v7v8lcf7q73a42q' },
          { type: 'columnSection', contentType: 'column-blocks', id: 'yvwacavb370l47h8j3vfvwk2' },
          { type: 'columnSection', contentType: 'column-blocks', id: 'eu5dagfqm3v01mt1v6sluyns' },
          { type: 'rowSection', contentType: 'row-blocks', id: 'g8aow64b8ria238uh5tq8ehp' },
          { type: 'carousel', contentType: 'carousels', id: 'mspxbpkqyhaz9kya5nzyj6j1' },
          { type: 'columnSection', contentType: 'column-blocks', id: 'ia7rbn7znlnlsop9zyt02jpo' },
          { type: 'rowSection', contentType: 'row-blocks', id: 'u68nm9crvfgrluw2942jtauq' },
        ],
        shutters: [
          { type: 'singleContent', contentType: 'single-contents', id: 'sm2ksdjxb4cy6xexe754p07g' },
          { type: 'heroBanner', contentType: 'hero-banners', id: 'l3o0ot3b698dahn4wgm3anny' },
          { type: 'formBlock', contentType: 'form-blocks', id: 'wcv7rtalcx7kua7uk0jxkd04' },
          { type: 'rowSection', contentType: 'row-blocks', id: 'pzy0ruqcys667qta3o5lcepi' },
          { type: 'columnSection', contentType: 'column-blocks', id: 'cr6aomr0ndjasmwt229q4jzo' },
          { type: 'columnSection', contentType: 'column-blocks', id: 'ln0ct6k1hrkdt9hntvqajlik' },
          { type: 'rowSection', contentType: 'row-blocks', id: 'sixiq6lk0z77am96lvtg3en9' },
          { type: 'carousel', contentType: 'carousels', id: 'rdhxzf197nq9hhvfn75brw4z' },
          { type: 'columnSection', contentType: 'column-blocks', id: 'ia7rbn7znlnlsop9zyt02jpo' },
          { type: 'rowSection', contentType: 'row-blocks', id: 'olg4dzycvfcvmggw71fl7mvv' },
        ],
      },
    },
    headerImage: 'bbLogo.jpg',
    colors: {
      '--color-background-light': '#ffffff',
      '--color-section-background': '#f5f5f5',
      '--color-primary': '#3B5072',
      '--color-secondary': '#6397AD',
      '--color-accent': '#E69B78',
      '--color-cta': '#E69B78',
      '--color-text-light': '#fff',
      '--color-text-dark': '#000000',
      '--color-text-primary': '#3B5072',
      '--color-text-secondary': '#555',
      '--color-border': '#ccc',
      '--color-error': 'red',
      '--color-h1': 'black'
    },
    ctaText: 'FREE CONSULTATION',
    variables: {
      'brooklyn': {
        location: 'Brooklyn',
        city: 'Brooklyn',
        state: 'NY',
        phone: '917-692-4155',
        address: 'Brooklyn, NY',
        scriptParams: {
          i: '671a4e9ba607612bec51ebf7',
          s: '0b8630ed853e0030edee03c171f54bbc',
        },
        testimonial: { contentType: 'testimonials', id: 'u8u7a5ge99wwhn6pe45yurxl' },
      },
      'edison': {
        location: 'Edison',
        city: 'Edison',
        state: 'NJ',
        phone: '609-933-5685',
        address: 'Edison, NJ',
        scriptParams: {
          i: '671a4ecea607612bec51ebf8',
          s: 'cef93ecb0543128cd26f33fb0b229972',
        },
        testimonial: { contentType: 'testimonials', id: 'f8ooboe9nz3w321bp9zsy1x0' },
      },
      'middletown': {
        location: 'Middletown',
        city: 'Middletown',
        state: 'CT',
        phone: '860-613-6266',
        address: 'Middletown, CT',
        scriptParams: {
          i: '671a4f06a607612bec51ebf9',
          s: '563c5211c58207e149fc0f7c90c0bfc9',
        },
        testimonial: { contentType: 'testimonials', id: 'u4yo5mqcl6iy7pego7zivejz' },
      },      
    },
    gtmId: 'GTM-KX2MV7ZZ',
  },
  'www.restoration-1.us': {
    fontFamily: "'Avenir Next', sans-serif",
    favicon: 'r1Favicon.png',
    metaTitle: 'Restoration 1',
    components: {
      default: {
        default: [
          { type: 'heroBanner', contentType: 'hero-banners', id: 'e45q0fdg25x31vnem50q25lj' },
          { type: 'columnSection', contentType: 'column-blocks', id: 'g3hbsdlus4hdpvkpi4274xen' },
          { type: 'columnSection', contentType: 'column-blocks', id: 'eofaoyhou0mokt98m96p87s3' },
          { type: 'columnSection', contentType: 'column-blocks', id: 'uueas49kcpv8zmro4uke2al1' },
          { type: 'formBlock', contentType: 'form-blocks', id: 'of7wxqv53gvp8juobamx97ig' },
          { type: 'columnSection', contentType: 'column-blocks', id: 'x9czt9xa45bprabdtg11axy3' },
        ],
      },
    },
    headerImage: 'res1Logo.png',
    colors: {
      '--color-background-light': '#ffffff',
      '--color-section-background': '#f5f5f5',
      '--color-primary': '#0A1C2F',
      '--color-secondary': '#0A1C2F',
      '--color-accent': '#E69B78',
      '--color-cta': '#F58020',
      '--color-text-light': '#fff',
      '--color-text-dark': '#666',
      '--color-text-primary': '#0A1C2F',
      '--color-text-secondary': '#555',
      '--color-border': '#ccc',
      '--color-error': 'red',
      '--color-h1': 'black'
    },
    ctaText: 'Free Consultation',
    variables: {
      'nwcobb': {
        location: 'NW Cobb',
        city: 'Hiram',
        state: 'GA',
        phone: '678-681-1522',
        address: '3595 Hiram Douglasville Hwy #221',
        scriptParams: {
          i: '6765c9aa65676f70b1aa9dfe',
          s: '59938c64255cba9a3b3209890524ffc8',
        },
        // testimonial: { contentType: 'testimonials', id: 'u8u7a5ge99wwhn6pe45yurxl' },
      },
    }, 
    gtmId: 'GTM-PWQWSXCX',
  },
  'www.tailoredcloset.us': {
    fontFamily: "'Avenir Next', sans-serif",
    metaTitle: 'The Tailored Closet',
    components: {
      default: {
        default: [
          { type: 'heroBanner', contentType: 'hero-banners', id: 'w182rnvabkkrlb77zawj2zdz' },
          { type: 'columnSection', contentType: 'column-blocks', id: 'bh6krc1tly9eyytdlri9k2s9' },
          { type: 'columnSection', contentType: 'column-blocks', id: 'q0nzwyu0321v3v1sqjyeoi82' },
          { type: 'carousel', contentType: 'carousels', id: 'y0q4key6s3641pgs5xtve0dc' },
          { type: 'formBlock', contentType: 'form-blocks', id: 'lf11i4k0u6vmxc6wd14wekj6' },
        ],
      },
    },
    headerImage: 'ttLogo.jpg',
    colors: {
      '--color-background-light': '#ffffff',
      '--color-section-background': '#f5f5f5',
      '--color-primary': '#3d3d3d',
      '--color-secondary': '#2d3a43',
      '--color-accent': '#2d3a43',
      '--color-cta': '#906a6f',
      '--color-text-light': '#fff',
      '--color-text-dark': '#666',
      '--color-text-primary': '#3d3d3d',
      '--color-text-secondary': '#555',
      '--color-border': '#ccc',
      '--color-error': 'red',
      '--color-h1': 'black'
    },
    ctaText: 'FREE CONSULTATION',
    variables: {
      'carlsbad': {
        location: 'Carlsbad',
        city: 'Carlsbad',
        state: 'CA',
        phone: '951-297-9858',
        address: 'Carlsbad, CA',
        scriptParams: {
          i: '671a4e69a607612bec51ebf6',
          s: 'ff905c2e3e943ec74022da6fef222d0e',
        },
        testimonial: { contentType: 'testimonials', id: 'qdd4bqqa2gailvw3nqahtzvm' },
      },
      'port-charlotte': {
        location: 'Port Charlotte',
        city: 'Port Charlotte',
        state: 'FL',
        phone: '941-212-2824',
        address: 'Port Charlotte, FL',
        scriptParams: {
          i: '671a4e33a607612bec51ebf4',
          s: 'b521c609c03ce3fc121c5ec382cefb04',
        },
        testimonial: { contentType: 'testimonials', id: 'fj50g7849tukqzrmr9esixdz' },
      },
      'nw-arkansas': {
        location: 'Northwest Arkansas',
        city: 'Springdale',
        state: 'AR',
        phone: '(479) 240-2178',
        address: 'P3903 Kelley Ave Suite A',
        scriptParams: {
          i: '6765bd8f65676f70b1aa9dfd',
          s: 'be988796154be7e4a60fc45ae1517954',
        },
        // testimonial: { contentType: 'testimonials', id: 'fj50g7849tukqzrmr9esixdz' },
      },
    },
    gtmId: 'GTM-MDRZFGGX',
  },
  'www.express-pros.us': {
    fontFamily: "'Roboto', sans-serif",
    favicon: 'expFavicon.png',
    metaTitle: 'Express Employment Professionals',
    components: {
      default: {
        default: [
          { type: 'singleContent', contentType: 'single-contents', id: 't3cm5qdblkofcvf88i7cdf9e' },
          { type: 'heroBanner', contentType: 'hero-banners', id: 'd427ftz645jvvujijhyu6st6' },
          { type: 'formBlock', contentType: 'form-blocks', id: 'f3hahixsbh7duvkar5d2gw4u' },
          { type: 'heroBanner', contentType: 'hero-banners', id: 'q1lanmhsxh6i835197rv7opg' },
          { type: 'rowSection', contentType: 'row-blocks', id: 'z1ifsdmdpae88zyui1bz0tdg' },
          { type: 'singleContent', contentType: 'single-contents', id: 'dqzcwwdspe3qoj8k5w0x91f4' },

        ],
      },
      clients: {
        default: [
          { type: 'formBlock', contentType: 'form-blocks', id: 'rzjuzssd9amfffbab6e976rh' },
          { type: 'columnSection', contentType: 'column-blocks', id: 'ghw72bzghv433qr9wg83388v' },
          { type: 'singleContent', contentType: 'single-contents', id: 'k4p0tgoiv2y5fzj32mgo0aap' },
          { type: 'overlapBlock', contentType: 'overlap-blocks', id: 'xpu4w0j3emj1wtk1xo3l9vwt' },
          { type: 'quadrantBlock', contentType: 'quadrant-blocks', id: 'd6zvtzjem5acozsmk8omkcta' },
          { type: 'contactBanner', contentType: 'contact-banners', id: 'wsg3jesozeskdf2zcaohx7ya' },
          { type: 'rowSection', contentType: 'row-blocks', id: 'ozflckchlujjuvhem34g06r3' },
          { type: 'singleContent', contentType: 'single-contents', id: 'dqzcwwdspe3qoj8k5w0x91f4' },
          { type: 'mapBlock', contentType: 'map-Blocks', id: 't4ja2adav3fyezqrfcyh4gw6' },
          { type: 'heroBanner', contentType: 'hero-banners', id: 'r174jaf35fcwpbuvdbx9w0tv' },
        ],
      },
    },
    headerImage: 'expLogo.png',
    colors: {
      '--color-background-light': '#ffffff',
      '--color-section-background': '#f5f5f5',
      '--color-primary': '#008AC5',
      '--color-secondary': '#005288',
      '--color-accent': '#ff7a00',
      '--color-cta': '#008AC5',
      '--color-text-light': '#fff',
      '--color-text-dark': '#000000',
      '--color-text-primary': '#005288',
      '--color-text-secondary': '#555',
      '--color-border': '#ccc',
      '--color-error': 'red',
      '--color-h1': 'black'
    },
    ctaText: 'Contact Us',
    variables: {
      'london': {
        location: 'London',
        city: 'London',
        state: 'Ontario',
        phone: '(519) 672-7620',
        address: '300 Dundas St, Suite 200',
        scriptParams: {
          i: 'X',
          s: 'X',
        },
        // testimonial: { contentType: 'testimonials', id: 'qdd4bqqa2gailvw3nqahtzvm' },
      },
      'fredericksburg': {
        location: 'Fredericksburg',
        city: 'Fredericksburg',
        state: 'VA',
        phone: '(571) 222-4238',
        address: '10707 Spotsylvania, Suite 103',
        scriptParams: {
          i: 'X',
          s: 'X',
        },
        // testimonial: { contentType: 'testimonials', id: 'wpyiim5u1y5xt5itulov4zk0' },
      },
      'anderson': {
        location: 'Anderson',
        city: 'Anderson',
        state: 'SC',
        phone: '(864) 844-9133',
        address: '3016 North Main Street, Suite G',
        scriptParams: {
          i: 'X',
          s: 'X',
        },
        // testimonial: { contentType: 'testimonials', id: 'qdd4bqqa2gailvw3nqahtzvm' },
      },
      'mississauga': {
        location: 'Mississauga',
        city: 'Mississauga',
        state: 'ON',
        phone: '905-281-3600',
        address: '377 Burnhamthorpe Road East Suite 112A',
        scriptParams: {
          i: 'X',
          s: 'X',
        },
        // testimonial: { contentType: 'testimonials', id: 'qdd4bqqa2gailvw3nqahtzvm' },
      },
      'fresno-west': {
        location: 'Fresno West',
        city: 'Fresno',
        state: 'CA',
        phone: '(559) 738-7822',
        address: '7411 North Cedar Suite 101',
        scriptParams: {
          i: 'X',
          s: 'X',
        },
        // testimonial: { contentType: 'testimonials', id: 'qdd4bqqa2gailvw3nqahtzvm' },
      },
      'honolulu': {
        location: 'Honolulu',
        city: 'Honolulu',
        state: 'HI',
        phone: '(808) 525-5225',
        address: '1130 North Nimitz Highway Suite A135',
        scriptParams: {
          i: 'X',
          s: 'X',
        },
        // testimonial: { contentType: 'testimonials', id: 'qdd4bqqa2gailvw3nqahtzvm' },
      },
      'honolulu-srg': {
        location: 'Honolulu SRG',
        city: 'Pearl City',
        state: 'HI',
        phone: '(808) 525-5226',
        address: '945 Kamehameha Highway Unit 5',
        scriptParams: {
          i: 'X',
          s: 'X',
        },
        // testimonial: { contentType: 'testimonials', id: 'qdd4bqqa2gailvw3nqahtzvm' },
      },
      'visalia': {
        location: 'Visalia',
        city: 'Visalia',
        state: 'CA',
        phone: '',
        address: '4002 S Demaree St Suite B',
        scriptParams: {
          i: 'X',
          s: 'X',
        },
        // testimonial: { contentType: 'testimonials', id: 'qdd4bqqa2gailvw3nqahtzvm' },
      },
      'atlanta-midtown': {
        location: 'Atlanta Midtown',
        city: 'Decatur',
        state: 'GA',
        phone: '678-515-3799',
        address: '910 Church Street Suite 203',
        scriptParams: {
          i: 'X',
          s: 'X',
        },
        // testimonial: { contentType: 'testimonials', id: 'qdd4bqqa2gailvw3nqahtzvm' },
      },
      'buffalo-south': {
        location: 'Buffalo South',
        city: 'West Seneca',
        state: 'NY',
        phone: '',
        address: '1900 Ridge Road Suite 111',
        scriptParams: {
          i: 'X',
          s: 'X',
        },
        // testimonial: { contentType: 'testimonials', id: 'qdd4bqqa2gailvw3nqahtzvm' },
      },
      'delaware': {
        location: 'Delaware',
        city: 'Delaware',
        state: 'OH',
        phone: '(614) 896-8643',
        address: '30 Troy Road',
        scriptParams: {
          i: 'X',
          s: 'X',
        },
        // testimonial: { contentType: 'testimonials', id: 'qdd4bqqa2gailvw3nqahtzvm' },
      },
      'frederick': {
        location: 'Frederick',
        city: 'Frederick',
        state: 'MD',
        phone: '(240) 716-5627',
        address: '5291 Corporate Drive Suite 201',
        scriptParams: {
          i: 'X',
          s: 'X',
        },
        // testimonial: { contentType: 'testimonials', id: 'qdd4bqqa2gailvw3nqahtzvm' },
      },
      'houston-east': {
        location: 'Houston East',
        city: 'Baytown',
        state: 'TX',
        phone: '281-968-3381',
        address: '8018 North Highway 146 Suite 2',
        scriptParams: {
          i: 'X',
          s: 'X',
        },
        // testimonial: { contentType: 'testimonials', id: 'qdd4bqqa2gailvw3nqahtzvm' },
      },
      'houston-west': {
        location: 'Houston West',
        city: 'Houston',
        state: 'TX',
        phone: '832-925-8980',
        address: '3100 S. Gessner Rd. Suite 206',
        scriptParams: {
          i: 'X',
          s: 'X',
        },
        // testimonial: { contentType: 'testimonials', id: 'qdd4bqqa2gailvw3nqahtzvm' },
      },
      'mankato': {
        location: 'Mankato',
        city: 'North Mankato',
        state: 'MN',
        phone: '507-387-5620',
        address: '113 Monroe Avenue',
        scriptParams: {
          i: 'X',
          s: 'X',
        },
        // testimonial: { contentType: 'testimonials', id: 'qdd4bqqa2gailvw3nqahtzvm' },
      },
      'myrtle-beach': {
        location: 'Myrtle Beach',
        city: 'Myrtle Beach',
        state: 'SC',
        phone: '843-212-9099',
        address: '1111 48th Avenue North Suite 116',
        scriptParams: {
          i: 'X',
          s: 'X',
        },
        // testimonial: { contentType: 'testimonials', id: 'qdd4bqqa2gailvw3nqahtzvm' },
      },
      'northbrook': {
        location: 'Northbrook',
        city: 'Northbrook',
        state: 'IL',
        phone: '847-739-2500',
        address: '869 Sanders Road',
        scriptParams: {
          i: 'X',
          s: 'X',
        },
        // testimonial: { contentType: 'testimonials', id: 'qdd4bqqa2gailvw3nqahtzvm' },
      },
      'pine-bluff': {
        location: 'Pine Bluff',
        city: 'Pine Bluff',
        state: 'AZ',
        phone: '870-535-3330',
        address: '2600 Olive Street',
        scriptParams: {
          i: 'X',
          s: 'X',
        },
        // testimonial: { contentType: 'testimonials', id: 'qdd4bqqa2gailvw3nqahtzvm' },
      },
      'kalispell': {
        location: 'Kalispell',
        city: 'Kalispell',
        state: 'MT',
        phone: '406-257-2255',
        address: '4 Sunset Plaza Suite 101',
        scriptParams: {
          i: 'X',
          s: 'X',
        },
        // testimonial: { contentType: 'testimonials', id: 'qdd4bqqa2gailvw3nqahtzvm' },
      },
      'covington': {
        location: 'Covington',
        city: 'Covington',
        state: 'LA',
        phone: '',
        address: '100 Louis Prima Drive The Orchard',
        scriptParams: {
          i: 'X',
          s: 'X',
        },
        // testimonial: { contentType: 'testimonials', id: 'qdd4bqqa2gailvw3nqahtzvm' },
      },
    },
    gtmId: 'GTM-TG4RP9X',
    formFields: {
      default: [
        { name: 'firstName', label: 'First name', type: 'text', required: true },
        { name: 'lastName', label: 'Last name', type: 'text', required: true },
        { name: 'email', label: 'Email', type: 'email', required: true },
        { name: 'phone', label: 'Phone', type: 'tel', required: true },
        { name: 'services', label: 'Type of work:', type: 'multi-select', required: true, options: [
          { label: 'Light industrial', value: 'Light industrial' },
          { label: 'Skill trades', value: 'Skill trades' },
          { label: 'Office services', value: 'Office services' },
          { label: 'Professional', value: 'Professional' },
        ]},
        { name: 'education', label: 'Education level:', type: 'single-select', required: true, options: [
          { label: 'High shcool', value: 'High shcool' },
          { label: 'Some college', value: 'Some college' },
          { label: 'Completed college', value: 'Completed college' },
          { label: 'Graduate shcool', value: 'Graduate shcool' },
        ]},
        { name: 'experience', label: 'Years of experience:', type: 'single-select', required: true, options: [
          { label: '1', value: '2' },
          { label: '2', value: '2' },
          { label: '3', value: '3' },
          { label: '4', value: '4' },
          { label: '5', value: '5+' },
        ]},
      ],
      clients: [
        { name: 'firstName', label: 'First name', type: 'text', required: true },
        { name: 'lastName', label: 'Last name', type: 'text', required: true },
        { name: 'workEmail', label: 'Work email', type: 'email', required: true },
        { name: 'companyName', label: 'Company name', type: 'text', required: true },
        { name: 'phone', label: 'Phone', type: 'tel', required: true },
        { name: 'services', label: 'I have positions to fill in the following areas (check all that apply):', type: 'multi-select', required: true, options: [
          { label: 'Office services', value: 'Office services' },
          { label: 'Light industrial', value: 'Light industrial' },
          { label: 'Professional', value: 'Professional' },
        ]},
      ],
    },
  },
};