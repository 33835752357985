import React from 'react';

function Footer({ privacyPolicy, websiteTerms }) {
  return (
    <footer className="global-footer">
      <p>© 2025 All rights reserved.</p>
      {privacyPolicy && (
        <a
          href={privacyPolicy}
          target="_blank"
          rel="noopener noreferrer"
          className="footer-link"
        >
          Privacy Policy
        </a>
      )}
      {websiteTerms && (
        <a
          href={websiteTerms}
          target="_blank"
          rel="noopener noreferrer"
          className="footer-link"
        >
          Website Terms & Conditions
        </a>
      )}
    </footer>
  );
}

export default Footer;