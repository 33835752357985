import React from 'react';
import AddressIcon from '../assets/address-icon.svg';
import PhoneIcon from '../assets/phone-icon.svg';

function Header({ headerImage, address, phone, city, state }) {
  // Construct a more accurate Google Maps URL
  const googleMapsQuery = [address, city, state]
    .filter(Boolean) // Ensure only non-empty values are included
    .join(', '); // Combine address, city, and state into a single string

  const googleMapsLink = googleMapsQuery
    ? `https://www.google.com/maps?q=${encodeURIComponent(googleMapsQuery)}`
    : '#';

  // Ensure the headerImage path is absolute
  const resolvedHeaderImage = headerImage.startsWith('/')
    ? headerImage // Absolute path already
    : `/${headerImage}`; // Add leading slash if missing

  return (
    <header className="global-header">
      <div className="address-container">
        {address && (
          <div className="address">
            <div className="address-content">
              <img src={AddressIcon} className="icon address-icon" alt="Address Icon" />
              <span className="text">{googleMapsQuery}</span>
            </div>
          </div>
        )}
      </div>
      <div className="logo">
        <img src={resolvedHeaderImage} alt="Logo" />
      </div>
      <div className="phone-container">
        {phone && (
          <div className="phone">
            <a href={`tel:${phone}`} aria-label={`Call ${phone}`}>
              <img src={PhoneIcon} className="icon phone-icon" alt="Phone Icon" />
              <span className="text">{phone}</span>
            </a>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;