import axios from 'axios';
import API_URL from '../config/contentApi';

const API_TOKEN = 'dc09d7b476cfbb7aa6067befbc45d3e02df652b4db2e8d3e292b9d6d54e702e8e4a3a41c6d342ad5498a450cf0b21fd29676fb6cfb1a9e0af6ec078a429dffa81faef5b884cd1c7136e713736ac8752639226855556991e45195ff89b5260146dca2f765def0c18b5e9c453ccbae10f9dbdf5932375070384732af755869429f';

const headers = {
  Authorization: `Bearer ${API_TOKEN}`,
};

// Define custom populate settings for different content types
const populateMap = {
  carousels: 'populate[Slide][populate]=*', // Custom populate for carousels
  testimonials: 'populate[Slide][populate]=*', // Custom populate for testimonial carousels
  'column-blocks': 'populate[Column][populate]=*',
  'quadrant-blocks': 'populate[Quadrant][populate]=*',
  // Add more content types here with specific populate fields if needed
};

export const fetchContent = async (contentType, id) => {
  try {
    // Get the custom populate string if it exists, otherwise default to populate all
    const queryString = populateMap[contentType.toLowerCase()] || 'populate=*';

    // Construct the full endpoint with the correct query string
    const endpoint = `${API_URL}/api/${contentType}/${id}?${queryString}`;
    
    console.log('Fetching from endpoint:', endpoint); // This should now show the correct query

    // Make the request to Strapi
    const response = await axios.get(endpoint, { headers });

    return response.data;
  } catch (error) {
    console.error('Error fetching content:', error);
    throw error;
  }
};
