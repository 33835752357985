import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import DynamicPage from './components/dynamicPage';
import { contentMap } from './config/contentMap';
import GTM from './components/tagManager';
import './index.css'; // Ensure the default styles are always loaded

function App() {
  const domain = window.location.hostname;
  const mapping = contentMap[domain];
  const gtmId = mapping && mapping.gtmId;

  // Function to load domain-specific CSS or fallback to default
  const loadCSS = async (isClientsPath) => {
    const existingLink = document.querySelector(`link[data-domain-css]`);
    if (existingLink) {
      existingLink.remove(); // Remove the old link to avoid conflicts
    }

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.dataset.domainCss = isClientsPath ? 'clients' : 'default';

    try {
      if (isClientsPath) {
        // Attempt to load the clients-specific SCSS
        await import(`./styles/exp-clients.scss`);
        link.href = `/customDomainCss/clients.css`; // Path to the compiled clients CSS
      } else {
        link.href = `/index.css`; // Fallback to the default styles
      }
    } catch (error) {
      console.warn(`CSS file for clients not found. Falling back to default styles.`);
      link.href = `/index.css`;
    }

    document.head.appendChild(link); // Append the CSS file to the <head>
  };

  // Hook to determine if the path contains `/clients/`
  const determinePath = () => {
    const pathname = window.location.pathname;
    return pathname.includes('/clients/');
  };

  useEffect(() => {
    const isClientsPath = determinePath();
    loadCSS(isClientsPath);
  }, [domain]);

  return (
    <>
      {gtmId && <GTM gtmId={gtmId} />} {/* Inject GTM based on the domain */}
      <Router>
        <Routes>
          <Route path="/:key" element={<DynamicPage />} /> {/* Use key in the URL */}
          <Route path="/clients/:key" element={<DynamicPage />} /> {/* Clients path */}
        </Routes>
      </Router>
    </>
  );
}

export default App;