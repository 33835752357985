export const updateFaviconAndTitle = (faviconPath, title) => {
  // Default fallback values
  const defaultFavicon = '/niaLogo.png';
  const defaultTitle = 'Your Offer';

  // Use provided values or fallbacks
  const finalFavicon = faviconPath ? `${window.location.origin}/${faviconPath}` : `${window.location.origin}${defaultFavicon}`;
  const finalTitle = title || defaultTitle;

  // Update the favicon
  const existingLink = document.querySelector("link[rel~='icon']");
  if (existingLink) {
    existingLink.href = finalFavicon; // Update existing favicon
  } else {
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = finalFavicon;
    document.getElementsByTagName('head')[0].appendChild(link); // Add favicon link to <head>
  }

  // Update the page title
  document.title = finalTitle;
};