import React from 'react';
import API_URL from '../config/contentApi';

function HeroBanner({ data, ctaText, scrollToForm, variables }) {
  if (!data) {
    return <div>Loading...</div>;
  }

  const { Title, Body, Image, MobileImage, Overlay, Button, SmsButton } = data;

  // Construct image URLs
  const desktopImageUrl = Image ? `${API_URL}${Image.url}` : null;
  const mobileImageUrl = MobileImage ? `${API_URL}${MobileImage.url}` : null;

  // Determine if there are any images
  const hasImage = desktopImageUrl || mobileImageUrl;

  // Phone number for SMS
  const phoneNumber = variables?.phone || 'Phone number';

  return (
    <div className={`hero-banner ${hasImage ? 'has-image' : 'no-image'}`}>
      {/* Responsive image handling */}
      {(desktopImageUrl || mobileImageUrl) && (
        <picture>
          {mobileImageUrl && (
            <source media="(max-width: 1024px)" srcSet={mobileImageUrl} />
          )}
          {desktopImageUrl && (
            <img src={desktopImageUrl} alt={Title || 'Hero Image'} className="hero-image" />
          )}
        </picture>
      )}

      {/* Conditional overlay */}
      {Overlay && <div className="overlay"></div>}

      {/* Content within the hero banner */}
      <div className="hero-banner-content">
        {Title && <h1>{Title}</h1>}
        {Body && <p>{Body}</p>}
        <div className="hero-banner-buttons">
          {Button && ctaText && (
            <button onClick={scrollToForm}>
              {ctaText}
            </button>
          )}
          {SmsButton && phoneNumber && (
            <a
              href={`sms:${phoneNumber}`}
              className="hero-banner-button sms-button"
            >
              Text Us
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default HeroBanner;