import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import API_URL from '../config/contentApi';

function Carousel({ data }) {
  if (!data) {
    return <div>Loading carousel...</div>;
  }

  const { Title: carouselTitle, Slide: slides } = data;

  if (!slides || slides.length === 0) {
    return <div>No slides available</div>;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000,
  };

  return (
    <div className="carousel-wrapper">
      {/* Carousel title */}
      {carouselTitle && <h2 className="carousel-title">{carouselTitle}</h2>}
      
      <div className="carousel">
        <Slider {...settings}>
          {slides.map((slide) => {
            const image = slide.Image?.[0]; // Safely access the first image if it exists
            const imageUrl = image?.formats?.large?.url || image?.url; // Get large image or fallback to original
            const hasContent = slide.Title || slide.Description;

            return (
              <div 
                key={slide.id} 
                className={`carousel-slide ${hasContent ? 'has-content' : 'no-content'}`}
              >
                {/* Image Container */}
                <div className="carousel-slide-image">
                  {imageUrl && (
                    <img
                      src={`${API_URL}${imageUrl}`}
                      alt={image?.alternativeText || 'Carousel Slide'}
                    />
                  )}
                </div>
                
                {/* Text Content */}
                {hasContent && (
                  <div className="carousel-slide-content">
                    {slide.Title && <h3>{slide.Title}</h3>}
                    {slide.Description && <p>{slide.Description}</p>}
                  </div>
                )}
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}

export default Carousel;