import React from 'react';
import API_URL from '../config/contentApi';

function QuadrantBlock({ data }) {
  if (!data || !data.Quadrant || data.Quadrant.length !== 4) {
    return <div>Invalid quadrant data</div>;
  }

  return (
    <div className="quadrant-block">
      <div className="quadrant-grid">
        {data.Quadrant.map((quadrant, index) => {
          const iconImage = quadrant.Image?.[0]?.url; // Safely access the image URL
          const imageAlt = quadrant.Image?.[0]?.alternativeText || `Icon ${index + 1}`;

          return (
            <div key={quadrant.id} className="quadrant-item">
              {/* Render icon image */}
              <div className="quadrant-icon">
                {iconImage && (
                  <img
                    src={`${API_URL}${iconImage}`}
                    alt={imageAlt}
                    className="quadrant-icon-image"
                  />
                )}
              </div>

              {/* Render content */}
              <div className="quadrant-content">
                <h3 className="quadrant-title">{quadrant.Title}</h3>
                <p className="quadrant-description">{quadrant.Description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default QuadrantBlock;