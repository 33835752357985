import React from 'react';
import API_URL from '../config/contentApi';

function SingleContent({ data }) {
  if (!data) {
    return <div>Loading content...</div>;
  }

  const { Title, Description, Content, VideoURL } = data;

  // Determine the content type (image or video)
  const contentUrl = Content ? `${API_URL}${Content.url}` : null;
  const isVideo = Content && Content.mime && Content.mime.startsWith('video');

  // Extract YouTube video ID from the VideoURL
  const getYouTubeEmbedUrl = (url) => {
    const match = url.match(/(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})/);
    return match ? `https://www.youtube.com/embed/${match[1]}` : null;
  };

  const youtubeEmbedUrl = VideoURL ? getYouTubeEmbedUrl(VideoURL) : null;

  return (
    <div className="single-content">
      {/* Optional Title */}
      {true && <h2 className="single-content-title">{Title}</h2>}

      {/* Optional Description */}
      {Description && <p className="single-content-description">{Description}</p>}

      {/* Render YouTube embed, image, or video */}
      <div className="single-content-media">
        {youtubeEmbedUrl ? (
          <iframe
            width="100%"
            height="315"
            src={youtubeEmbedUrl}
            title={Title || 'YouTube video'}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="single-content-video"
          ></iframe>
        ) : contentUrl ? (
          isVideo ? (
            <video controls className="single-content-video">
              <source src={contentUrl} type={Content.mime} />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              src={contentUrl}
              alt={Content.alternativeText || 'Single content'}
              className="single-content-image"
            />
          )
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
}

export default SingleContent;