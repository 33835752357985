import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { ReactComponent as CheckIcon } from '../assets/check-icon.svg';
import API_URL from '../config/contentApi';

const FormBlock = React.forwardRef(({ data, formFields, urlDomain, urlKey, variables }, ref) => {
  const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const formContainerRef = useRef(null);

  const EXCLUDED_KEYS = [
    'buffalo-south',
    'frederick',
    'fredericksburg',
    'fresno',
    'honolulu',
    'houston-east',
    'london',
    'mankato',
    'myrtle-beach',
    'northbrook',
    'honolulu-srg',
    'pine-bluff',
    'visalia',
    'covington',
    'longmont',
    'waltham-framingham',
    'woodbridge-vaugh'
  ];
  const shouldExcludeSingleSelect = EXCLUDED_KEYS.includes(urlKey);

  const onSubmit = async (formData) => {
    const submissionData = { ...formData, key: urlKey, location: variables?.location, urlDomain };

    try {
      const response = await fetch('https://cors-chat-548058780807.us-central1.run.app/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(submissionData),
      });

      const result = await response.json();
      if (response.ok) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'formSubmission',
          formId: urlKey,
          formLocation: variables?.location,
          formData: formData,
        });

        if (formContainerRef.current) {
          formContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }

        setFormSubmitted(true);
      } else {
        alert(`Failed to send form: ${result.error}`);
      }
    } catch (error) {
      alert('An error occurred. Please try again later.');
    }
  };

  if (!data) {
    return <div>Loading form block...</div>;
  }

  const { Title, Description, Body, Header, Label, Callout, Image, Consent } = data;

  const getBestImage = (imageFormats) => {
    const priorityOrder = ['large', 'medium', 'small', 'thumbnail'];
    for (const size of priorityOrder) {
      if (imageFormats?.[size]?.url) {
        return `${API_URL}${imageFormats[size].url}`;
      }
    }
    return null;
  };

  const imageUrl = Image && Image[0]?.formats ? getBestImage(Image[0].formats) : null;

  const location = variables?.location || 'Location';
  const address = variables?.address || 'Address';
  const phone = variables?.phone || 'Phone number';
  const iframeSrc = variables?.mapUrl;

  return (
    <div className="form-block" ref={ref}>
      <div className="form-block-left-column">
        <div className="aux-info-container">
          {Title && <div className="aux-title">{Title}</div>}
          {Body && <div className="aux-body">{Body}</div>}
        </div>

        {iframeSrc ? (
          <div className="map-embed-container">
            <iframe
              src={iframeSrc}
              width="100%"
              height="100%"
              style={{ border: 'none', borderRadius: '8px' }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        ) : (
          <img
            src={imageUrl || 'https://placehold.co/1221x680'}
            alt={Image?.[0]?.alternativeText || 'Form Image'}
            className="form-image"
          />
        )}

        <div className="contact-info-container">
          <div className="form-callout">
            Call us:
            <a href={`tel:${phone}`} className="form-callout-phone"> {phone || 'Phone'}</a>
          </div>
          {Callout && <div className="form-callout">{Callout}</div>}
          {Label && <div className="form-label">{Label}</div>}
        </div>
      </div>

      <div className="form-block-right-column" ref={formContainerRef}>
        {formSubmitted ? (
          <div className="success-screen">
            <h2>Thank You!</h2>
            <CheckIcon className="check-icon" />
            <p>We have received your information. Our team will get in contact with you soon.</p>
          </div>
        ) : (
          <div className="form-header">
            <h2>{Header}</h2>
            {Description && <p className="form-description">{Description}</p>}

            <div className="form-container">
              <form onSubmit={handleSubmit(onSubmit)}>
                {formFields
                  .filter((field) => !(shouldExcludeSingleSelect && field.type === 'single-select'))
                  .map((field, index) => {
                    if (field.type === 'multi-select') {
                      return (
                        <fieldset key={index} className="multi-select-fieldset">
                          <legend className="multi-select-label">{field.label}</legend>
                          <div className="checkbox-group">
                            {field.options.map((option, optionIndex) => (
                              <label key={optionIndex} className="checkbox-label">
                                <input
                                  type="checkbox"
                                  value={option.value}
                                  {...register(field.name)}
                                  onChange={(e) => {
                                    const currentValues = watch(field.name) || [];
                                    if (e.target.checked) {
                                      setValue(field.name, [...currentValues, option.value]);
                                    } else {
                                      setValue(
                                        field.name,
                                        currentValues.filter((val) => val !== option.value)
                                      );
                                    }
                                  }}
                                  className="checkbox-input"
                                />
                                <span className="checkbox-custom"></span>
                                {option.label}
                              </label>
                            ))}
                          </div>
                          {errors[field.name] && (
                            <p className="error">{errors[field.name].message}</p>
                          )}
                        </fieldset>
                      );
                    } else if (field.type === 'single-select') {
                      return (
                        <div key={index} className="form-field">
                          <label htmlFor={field.name} className="single-select-label">
                            {field.label}
                          </label>
                          <select
                            id={field.name}
                            {...register(
                              field.name,
                              field.required ? { required: `${field.label} is required` } : {}
                            )}
                            className="form-dropdown"
                          >
                            <option value="">Select an option</option>
                            {field.options.map((option, optionIndex) => (
                              <option key={optionIndex} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          {errors[field.name] && <p className="error">{errors[field.name].message}</p>}
                        </div>
                      );
                    } else {
                      return (
                        <div key={index} className="form-field">
                          <input
                            type={field.type}
                            placeholder={field.label}
                            {...register(
                              field.name,
                              field.required ? { required: `${field.label} is required` } : {}
                            )}
                          />
                          {errors[field.name] && (
                            <p className="error">{errors[field.name].message}</p>
                          )}
                        </div>
                      );
                    }
                  })}
                <button type="submit" className="form-submit">Contact Us</button>
                {Consent && <p className="consent-text">{Consent}</p>}
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default FormBlock;