import React, { useState } from 'react';
import API_URL from '../config/contentApi';

function RowSection({ data }) {
  const [activeIndex, setActiveIndex] = useState(null); // Always define hooks at the top

  if (!data) {
    return <div>Loading row block...</div>;
  }

  const { Title, Body, Row, Accordion, Image } = data;

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Toggle accordion tab
  };

  // Determine image URL if present
  const imageUrl = Image?.formats?.small?.url || Image?.url;
  const imageAlt = Image?.alternativeText || 'Row Section Image';

  return (
    <div className={`row-block ${Title ? 'with-title' : ''}`}>
      {/* Render the block title if it exists */}
      {Title && <h2 className="row-block-title">{Title}</h2>}

      {/* Render the block body if it exists */}
      {Body && <p className="row-block-body">{Body}</p>}

      <div className={`rows ${Accordion ? 'accordion-mode' : ''}`}>
        <div className="row-container">
          {/* Conditionally render the image */}
          {imageUrl && (
            <div className="row-image">
              <img src={`${API_URL}${imageUrl}`} alt={imageAlt} />
            </div>
          )}

          {/* Content Section */}
          <div className={`row-content ${imageUrl ? 'with-image' : ''}`}>
            {Row && Row.length > 0 ? (
              Row.map((row, index) => (
                <div
                  key={row.id}
                  className={`row ${Accordion ? 'accordion-row' : ''}`}
                >
                  {Accordion ? (
                    <>
                      {/* Accordion Tab (Subtitle) */}
                      <div
                        className="accordion-header"
                        onClick={() => toggleAccordion(index)}
                      >
                        <h3 className="row-subtitle">{row.Subtitle}</h3>
                      </div>
                      {/* Accordion Content */}
                      {activeIndex === index && (
                        <div className="accordion-content">
                          <p className="row-body">{row.Body}</p>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {/* Standard Row Layout */}
                      {row.Subtitle && <h3 className="row-subtitle">{row.Subtitle}</h3>}
                      {row.Body && <p className="row-body">{row.Body}</p>}
                    </>
                  )}
                </div>
              ))
            ) : (
              <p>No rows available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RowSection;