export const replacePlaceholders = (data, variables) => {
  if (typeof data === 'string') {
    return data.replace(/\[([^\]]+)\]/g, (match, p1) => variables[p1] || match);
  } else if (Array.isArray(data)) {
    return data.map(item => replacePlaceholders(item, variables));
  } else if (typeof data === 'object' && data !== null) {
    const newData = {};
    for (const key in data) {
      newData[key] = replacePlaceholders(data[key], variables);
    }
    return newData;
  } else {
    return data;
  }
}