import React from 'react';

function ContactBanner({ data, variables, scrollToForm }) {
  if (!data) {
    return <div>Loading...</div>;
  }

  const { Title, ButtonText } = data;
  const phone = variables?.phone || 'Phone number';

  return (
    <div className="contact-banner">
      <div className="contact-banner-content">
        {Title && (
          <div className="contact-banner-text">
            <h2 className="contact-banner-title">{Title}</h2>
          </div>
        )}
        <div className="contact-banner-buttons">
          <a href={`tel:${phone}`} className="contact-banner-button secondary">
            {phone}
          </a>
          {ButtonText && (
            <button
              className="contact-banner-button"
              onClick={scrollToForm}
            >
              {ButtonText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ContactBanner;

